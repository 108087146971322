import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header z-index-100">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Join us with dreams {""} <br /> Leave with Experience!
              </h1> 
              <h1 className="heading-name">
              Break Boundaries, Build Dreams <br />
                <strong className="main-name"> Start Your Developer Journey Now!</strong>
              </h1> 
              <a href="https://forms.gle/fihvL1aKxtEcLS5b7" className="btn-primary btn mx-5 mt-5">
                Get started 
              </a> 
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={homeLogo}
                loading="lazy"
                alt="home pic"
                className="img-fluid showpc contact-image"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
