import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-3">
          <p style={{ textAlign: "justify" }}>
            CNS Global is a <span className="purple">Digital Product studio </span>
            which helps businesses <br /> launch their <span className="purple"> custom software. </span>
            We provide strategy, design <br /> and engineering completely customized  to your business needs.
            <br /> <br />
            Students who are looking for experience <br /> can join our internship program and build their career.
            <br />
            <br />
            Discover the process to join us: 
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Connect with us 
            </li>
            <li className="about-activity">
              <ImPointRight /> Start your internship
            </li>
            <li className="about-activity">
              <ImPointRight /> Certificates and Goodies
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Tech for Tomorrow, Building Futures with Tech!"{" "}
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
