import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";

function About() {
  return ( 
    <Container fluid className="about-section">
      <Particle /> 
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }} className="mb-5 pb-5">
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
            className="aboutsec100vh" 
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple">Digital Product</strong> Studio
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img mt-4"
          >
            <img src={laptopImg} loading="lazy" alt="about" className="img-fluid showpc" />
          </Col>
        </Row>
        <h1 className="project-heading">
          Available <strong className="purple">Courses  </strong>
        </h1>
        <Techstack />
        <div className="sectionprocess">
        <h1 className="project-heading mt-5">
          Let's View Our <strong className="purple">Process </strong>
        </h1>
        <Toolstack />
        </div>

      </Container>

        <Github />
        
    </Container>
  );
}

export default About;
