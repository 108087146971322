import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Verification = () => {

  const [ref, setRef] = useState();
  const [valid, setValid] = useState(false);
  const [data, setdata] = useState();
  const navigate = useNavigate();
  
  useEffect(() => {

    const fetchData = async(url)=>{
      try{
        const response = (await axios.get(url)).data.result[0];
        setdata(response);
        if(response) setValid(true);
      }
      catch(error){
        setValid(false);
        console.error(error);
      }
    }

    const ref = window.location.search.slice(5).toUpperCase();
    setRef(ref);
    if(!ref) navigate('/')
    else{
      const query = `*[_type=='intern'&&ref=='${ref}']`
      const url = 'https://09gemjok.api.sanity.io/v2022-03-07/data/query/production?query=' + encodeURIComponent(query);
      fetchData(url)
    }
  }, [])
  
  return (
    <div className='about-section'>
      {
      valid ?
      <>
        <h1 className='mt-3'> Valid Certificate </h1>
       <div className='texalignleft container mt-5 mb-5 pb-5 px-5'>
       <h4 className='mt-5'>Name: {data.name}</h4>
        <h4 className='mt-3'>Domain: {data.domain}</h4>
        <h4 className='mt-3 mb-5'>Ref ID: {data.ref}</h4>
       </div>
      </> 
        
      : 
      <>
      <div className='mb-5 pb-5'>
        <h1 className='mt-5 mb-5 pt-5 pb-5'> Certificate Not Found/ Valid  </h1>
      </div>
      </>
      }
    </div>
  )
}

export default Verification