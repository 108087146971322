import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaLaptop } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";


function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }} className="mt-5 mb-5 pb-5">
      <Col xs={8} lg={2} className="tech-icons">
        <MdEmail />
        <h4 className="mt-3">Email your resume </h4>
      </Col>
      <Col xs={8} lg={2} className="tech-icons">
        <FaLaptop />
        <h4 className="mt-3">Start your internship</h4>
      </Col>
      <Col xs={8} lg={2} className="tech-icons">
        <TbCertificate />
        <h4 className="mt-3">Certificates and Goodies  </h4>
      </Col>
    </Row>
  );
}

export default Toolstack;
