import React from "react";
import { Row } from "react-bootstrap";

function Github() {
  return (
    <Row className="github mt-5" style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading mt-5 pt-5" style={{ paddingBottom: "20px" }}>
        <strong className="purple">Free </strong> Newsletter!
      </h1>
      <h2 className="project-heading mb-4">YOUR COMPETITIVE ADVANTAGE </h2>
      <div className="news-col">
      <input type="email" placeholder="Your email" className="inline newsletter-input" /> 
      <button className="btn btn-primary inline ">Subscribe Now </button>
      </div>
    </Row>
  );
}

export default Github;
