import { Container, Row, Col } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Particle from "../Particle";
import contactimg from "../../Assets/contactus.png";
import { FaWhatsapp } from "react-icons/fa";
import emailjs from '@emailjs/browser';
import { useState } from "react";


function ResumeNew() {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [college, setCollege] = useState('');
  const [city, setCity] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);


  emailjs.init({
    publicKey: process.env.REACT_APP_PUBLIC_KEY,
    blockHeadless: true,
  });

  const handleSubmit = async(e)=>{
    e.preventDefault();

    await emailjs.send('cnsglobal', 'cnsglobal', {
      firstName,
      lastName,
      email,
      college,
      city,
      message
    });

    setShow(true);
    setFirstName('');
    setLastName('');
    setEmail('');
    setCollege('');
    setCity('');
    setMessage('');
  }

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row>
          <Col lg={6}>
            <img src={contactimg} loading="lazy" className="contact-image" alt="contact" width={400} height={500} />
          </Col>
          <Col lg={6} className="z-index-100">
            <h5 className="contact-title">
              Talk to Our team to create a customized inbound developer program that bends 
              <span className="main-name"> proven strategy </span> 
              with tactical instruction and 
              <span className="main-name"> guarantees results</span>
            </h5>

            <form className="contact-form" onSubmit={(e)=> handleSubmit(e)}>
            <a href="http://tinyurl.com/cnsglobal" target="_blank" rel="noreferrer" className="btn contact-btn btn-primary my-3">
              <FaWhatsapp />&nbsp;Whatsapp  
            </a> <br />
            {show? <Alert variant="success" className="contact-message" onClose={() => setShow(false)} dismissible>
                Message sent Succesfully 
            </Alert> : <></>}
              <input value={firstName} required onChange={(e)=>setFirstName(e.target.value)} type="text" name="" className=" mt-3 contact-input" placeholder="First Name" id="" />
              <input value={lastName} required onChange={(e)=>setLastName(e.target.value)} type="text" name="" className="my-2 mt-3 marl-4 contact-input" placeholder="Last Name" id="" />
              <input value={email} required onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Your email" className="block my-2 marl-3 contact-email contact-input" name="" id="" />
              <input value={college} required onChange={(e)=>setCollege(e.target.value)} type="text" name="" className="my-2 mt-2 contact-input" placeholder="College Name" id="" />
              <input value={city} required onChange={(e)=>setCity(e.target.value)} type="text" name="" className="my-2 mt-2 marl-4 contact-input" placeholder="City" id="" />
              <textarea value={message} required onChange={(e)=>setMessage(e.target.value)} name="" id="" cols="30" rows="5" className="block mt-2 marl-3 contact-message " placeholder="Message"></textarea>
              <input type="submit" value="Submit" className="mt-4 contact-submit btn btn-primary" />
            </form>
          
          </Col>
        </Row>  
      </Container>
    </div>
  );
}

export default ResumeNew;
