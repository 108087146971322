import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  let date = new Date();
  let year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright">
          <h1 className="footerh1 cnsnamefooter mt-5">CNS Global</h1>
          <p className="footerh2">
            We are an Organization which aims to help students learn valuable skills through technology
          </p>
        </Col>
        <Col md="4" className="footer-copywright">
          <p className="footerh1 mt-5">Email your resume at hr@cnsglobal.tech </p>
        </Col> 
        <Col md="4" className="footer-body">
          <p className="footerh1 mt-4">Follow Us on: </p> 
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/cns-global"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub />
                <p style={{display: "none"}}>Github </p>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://twitter.com/cnsglobaltech"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiOutlineTwitter />
                <p style={{display: "none"}}>Twitter </p>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://linkedin.com/company/cnsglobal/"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
                <p style={{display: "none"}}>Linkedin </p>
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/cnsglobal.tech"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillInstagram /> 
                <p style={{display: "none"}}>Instagram</p>
              </a>
            </li>
          </ul>
        </Col> 
      <p style={{color: "white"}}>Copyright © {year} CNS Global </p> 
      </Row>
    </Container>
  );
}

export default Footer;
