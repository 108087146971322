import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Github from "../About/Github";
import Tilt from "react-parallax-tilt";
import { FaCheckCircle } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 className="intro-tagline" style={{ fontSize: "2.6em" }}>
              <span className="purple"> CNS :</span> Where Technology <br /> Meets Talent - Internships Crafting Future Innovators
            </h1>
            <p className="home-about-body">
            Embark on a transformative journey with us at CNS. As newcomers to the tech scene, we're passionate about providing unparalleled opportunities for aspiring talent. Join our internship program, where you'll gain hands-on experience in cutting-edge projects guided by seasoned professionals. At CNS, we foster an environment of innovation and collaboration, empowering you to unleash your potential and shape the future of technology. Start your journey with us today and turn your ambitions into tangible achievements.
            </p>
            <Row>
              <Col lg={6}>
              <ul className="features-list"> 
                <li> <FaCheckCircle /> Own Internship Platform </li>
                <li> <FaCheckCircle /> Recognized by LinkedIn  </li>
                <li> <FaCheckCircle /> Opportunities for hiring </li>
              </ul>
              </Col>
              <Col lg={6}>
              <ul className="features-list">
                <li> <FaCheckCircle /> Recognized by Govt. of India </li>
                <li> <FaCheckCircle /> Pocket Friendly </li>
              </ul>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Github />
        {/* <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND US ON</h1>
            <p>
              Connect with us for exciting  <span className="purple">Opportunities </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row> */}
      </Container>
    </Container>
  );
}
export default Home2;
