import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Strategy, Design and <strong className="purple">Engineering </strong>
        </h1>
        <p style={{ color: "white" }}>
          Completely customized for your business
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="AI Chatbots"
              description="We develop AI chatbots that engage users in natural and meaningful conversations. Using cutting-edge technologies like NLP and machine learning, we tailor the chatbot to your specific business need."
              demoLink="mailto:work@cnsglobal.tech?subject=Need Quotation for AI Chatbots"
              />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Website Development"
              description="We build websites tailored specifically to business needs like E-commerce, Blog post, Content Management, Business Process Management etc. with latest technologies and clean architecture."
              demoLink="mailto:work@cnsglobal.tech?subject=Need Quotation for Website Development"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Analytics"
              description="Get your business analytics where you get insights on how your business is performing. Inventory Management, Human Resource management and Customer Relations management is made easy with our tools"
              demoLink="mailto:work@cnsglobal.tech?subject=Need Quotation for Analytics"              
              />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Machine Learning"
              description="We develop custom AI models tailored to specific business needs, which can perform tasks, automate stuff and make smart decisions. These AI models use technology to the fullest extent to complete your tasks"
              demoLink="mailto:work@cnsglobal.tech?subject=Need Quotation for ML Projects"              
              />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Deep Learning"
              description="We use advanced tools to make custom deep learning models which work on neural networks. It provides the maximum output with minimum waiting time."
              demoLink="mailto:work@cnsglobal.tech?subject=Need Quotation for Deep Learning Projects" 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
