import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiReact,
  DiNodejs,
  DiPython,
  DiJava,
} from "react-icons/di";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <CgCPlusPlus />
        <h6>Elevate your C++ skills and be a master programmer </h6>
      </Col>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <h4 className="mt-5">Machine Learning</h4>
        <h6 className="mt-4">Build Projects, train Models and improve your ML game </h6>
      </Col>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <h4 className="mt-5">Deep Learning</h4>
        <h6 className="mt-4">Discover, learn and build deep Learning projects with neural networks </h6>
      </Col>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <DiNodejs />
        <h6 className="mt-1"> Javascript Backend Mastery with &nbsp; Express Js </h6>
      </Col>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <DiReact />
        <h4 className="">Web Development </h4>
        <h6 className="mt-1"> Build websites like a pro  </h6>
      </Col>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <DiPython /> 
        <h4 className="">Python  </h4>
        <h6 className="mt-1"> Python Projects with Django  </h6>
      </Col>
      <Col xs={7} md={2} lg={3} className="tech-icons">
        <DiJava /> 
        <h4 className="">Java </h4>
        <h6 className="mt-1"> Be a job ready Java Developer </h6>
      </Col>
    </Row>
  );
}

export default Techstack;
